import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { MessageBar, MessageBarType, Spinner } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import { LicenceTypeCodes } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

// The supplied date, expiryDate, is within plus 1 month of today, 1 month, the whole month and nothing but the month.
const isExpiredMonth = (expiryDate: DateTime) => {
  return (
    expiryDate < DateTime.today().plus({ months: 1 }) &&
    expiryDate >= DateTime.today()
  );
};

const isExpired = (expiryDate: DateTime) => {
  return expiryDate <= DateTime.now();
};

export const AccCertCheck: FunctionComponent = observer(() => {
  const { core } = useStores();

  // Only concerned with New Zealand Allied clients.
  if (core.tenantDetails?.country === Country.Australia) {
    return null;
  }

  const isAlliedNZ = core.licences.some(
    x => x.licenceTypeCode === LicenceTypeCodes.AlliedNZ
  );

  // Only concerned with New Zealand Allied clients.
  if (isAlliedNZ === false) {
    return null;
  }

  return (
    <DataFetcher
      fetch={({ acc }) => acc.getHealthCertificate()}
      fallback={<Spinner />}
    >
      {healthCertificate => {
        const certificateExpireMonth =
          healthCertificate?.certificateExpiryDate &&
          isExpiredMonth(
            DateTime.fromISO(healthCertificate.certificateExpiryDate)
          );

        const certificateExpired =
          healthCertificate?.certificateExpiryDate &&
          isExpired(DateTime.fromISO(healthCertificate.certificateExpiryDate));

        return (
          <div>
            {healthCertificate ? (
              <>
                {certificateExpireMonth ? (
                  // color yellow
                  <MessageBar messageBarType={MessageBarType.warning}>
                    Your ACC health certificate will expire on&nbsp;
                    {DateTime.fromISO(
                      healthCertificate.certificateExpiryDate
                    ).toDayDefaultFormat()}
                    . Get a new certificate from ACC and upload it before&nbsp;
                    {DateTime.fromISO(
                      healthCertificate.certificateExpiryDate
                    ).toDayDefaultFormat()}
                    &nbsp; to use ACC functions -
                    <Navigate to={routes.settings.practices.basePath.pattern}>
                      Practice details
                    </Navigate>
                  </MessageBar>
                ) : (
                  ""
                )}
                {certificateExpired ? (
                  // color pink
                  <MessageBar messageBarType={MessageBarType.severeWarning}>
                    ACC health certificate has expired. Upload your current
                    version -
                    <Navigate to={routes.settings.practices.basePath.pattern}>
                      Practice details
                    </Navigate>
                  </MessageBar>
                ) : (
                  ""
                )}
              </>
            ) : (
              // color pink
              <MessageBar messageBarType={MessageBarType.severeWarning}>
                No ACC health certificate. Upload your current certificate -
                <Navigate to={routes.settings.practices.basePath.pattern}>
                  Practice details
                </Navigate>
              </MessageBar>
            )}
          </div>
        );
      }}
    </DataFetcher>
  );
});
