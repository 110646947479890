import { Country } from "@libs/enums/country.enum.ts";
import { routes } from "@libs/routing/routes.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

export const getAdminNavLinks = (
  { correspondence, document, formDesign, templateManagement }: IRootStore,
  patientLabel: string
) => [
  {
    name: "Incoming",
    path: routes.inbox.incoming,
    showBorder: true
  },
  {
    name: `Upload ${patientLabel.toLocaleLowerCase()} files`,
    path: routes.inbox.upload,
    showBorder: true
  },
  {
    name: "Manage letter templates",
    path: routes.documentWriter.manageTemplate,
    onClick: () => {
      document.setShowTemplateManagementModal(true);
    },
    showBorder: false
  },
  {
    name: "Write a letter",
    path: routes.documentWriter.createDocument,
    onClick: () => {
      correspondence.ui.setShowTemplatePicker(true);
    },
    showBorder: true
  },
  {
    name: "Manage autofills",
    path: routes.documentWriter.autofillTemplate,
    onClick: () => {
      document.setShowTemplateManagementModal(true, true);
    },
    showBorder: true
  },
  {
    name: "ACC claims",
    path: routes.claims.basePath,
    tenantLocation: Country.NewZealand,
    showBorder: true
  },
  {
    name: "Online forms",
    path: routes.formDesign.basePath,
    onClick: () => {
      formDesign.ui.setShowFormDesignListModal(true);
    },
    showBorder: true
  },
  {
    name: "Template management",
    path: routes.templateManagement.basePath,
    onClick: () => {
      templateManagement.ui.setShowTemplateListModal(true);
    },
    showBorder: true
  },
  {
    name: "Recent patients",
    path: routes.recentPatients.basePath,
    showBorder: true
  }
];

export const AdminNavButton = () => {
  const store = useStores();
  const patientLabel = usePatientLabel(false);

  return (
    <Nav
      renderAsButton
      buttonProps={{ text: "Admin" }}
      links={getAdminNavLinks(store, patientLabel)}
    />
  );
};
