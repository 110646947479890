import { UserSessionCoachMarkModal } from "app-shell/session/components/UserSessionCoachMarkModal/UserSessionCoachMarkModal.tsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRef } from "react";

import {
  dataAttribute,
  DataAttributes,
  getPersonaInitialsColor,
  PersonaCoin,
  PersonaSize,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useRouteDefinitionsFilter } from "@stores/router/hooks/useRouteDefinitionsFilter.ts";
import { Nav, NavLink } from "@ui-components/navigation/Nav.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { useAuthorisationContext } from "../session/context/AuthorisationContext.tsx";
import { useMsalBrowserStorage } from "../session/hooks/useMsalBrowserStorage.ts";

export const UserNavButton = observer(() => {
  const { palette } = useTheme();
  const { wipeInteractionInProgress } = useMsalBrowserStorage();
  const routeFilter = useRouteDefinitionsFilter();

  const { core, routing } = useStores();
  const { userSessionTimeTracker, logoutAllClients } =
    useAuthorisationContext();

  const targetButton = useRef<HTMLDivElement>(null);

  const shouldSeeSettings = core.hasPermissions(
    [
      Permission.ScheduleWrite,
      Permission.CommunicationTemplateWrite,
      Permission.AppointmentReminderScheduleWrite,
      Permission.AppointmentTypeWrite,
      Permission.SecurityWrite
    ],
    "or"
  );

  const settingsRoute = routeFilter({ path: routes.settings.users.basePath })
    ? routes.settings.users.basePath
    : routes.settings.practices.basePath;

  const profile: NavLink = {
    name: "Profile",
    path: routes.settings.users.user,
    key: routes.settings.users.user.path({
      id: core.userId
    }),
    onClick: () =>
      routing.push(
        routes.settings.users.user.path({
          id: core.userId
        })
      ),
    dataAttributeName: "user-options-profile-link"
  };

  const recentPatients: NavLink = {
    name: "Recent patients",
    path: routes.recentPatients.basePath,
    dataAttributeName: "recent-patents-link"
  };

  const settings: NavLink = {
    name: "Settings",
    key: settingsRoute.pattern,
    onClick: () => routing.push(settingsRoute.pattern),
    dataAttributeName: "user-options-settings-link"
  };

  const logout: NavLink = {
    key: "logout",
    name: "Logout",
    onClick: async () => {
      wipeInteractionInProgress();
      await logoutAllClients();
    },
    dataAttributeName: "user-options-logout-link"
  };

  const fullNameAttribute = { fullname: core.user?.fullName };

  const concatenateSettings = () => {
    const settingsArray = [profile];
    if (
      core.hasPermissions(
        [Permission.ContactRead, Permission.PatientRead],
        "and"
      )
    ) {
      settingsArray.push(recentPatients);
    }
    if (shouldSeeSettings) {
      settingsArray.push(settings);
    }
    settingsArray.push(logout);
    return settingsArray;
  };

  return (
    <Nav
      renderAsButton
      buttonProps={{
        dataAttributeElementName: "user-options-link"
      }}
      links={concatenateSettings()}
    >
      <div
        ref={targetButton}
        {...fullNameAttribute}
        {...dataAttribute(DataAttributes.Id, core.user?.email)}
      >
        <PersonaCoin
          styles={{
            coin: {
              margin: "0 4px",
              border: userSessionTimeTracker.isReachedTimeOut
                ? `8px double ${palette.redDark}`
                : undefined,
              borderRadius: userSessionTimeTracker.isReachedTimeOut
                ? 100
                : undefined
            },
            imageArea: {
              backgroundColor: userSessionTimeTracker.isReachedTimeOut
                ? palette.redDark
                : undefined,
              borderRadius: userSessionTimeTracker.isReachedTimeOut
                ? 10
                : undefined,
              border: userSessionTimeTracker.isReachedTimeOut
                ? `1px solid ${palette.redDark}`
                : undefined
            }
          }}
          imageInitials={
            !userSessionTimeTracker.isReachedTimeOut
              ? core.user?.initials
              : `${userSessionTimeTracker.endSessionTimeLeftInMinutes}m`
          }
          size={PersonaSize.size24}
          initialsColor={
            !userSessionTimeTracker.isReachedTimeOut
              ? getPersonaInitialsColor({
                  text: core.user?.initials
                })
              : palette.redDark
          }
          onMouseEnter={() => {
            runInAction(() => {
              userSessionTimeTracker.hasClosedCoachMark = false;
            });
          }}
        />
      </div>
      <When permission={Permission.InactiveLockScreenAllowed}>
        <UserSessionCoachMarkModal targetButton={targetButton.current} />
      </When>
    </Nav>
  );
});
