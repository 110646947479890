import { useCallback, useEffect } from "react";

import { AppInsightsEventNames } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { useAppTrackEvent } from "@libs/analytics/app-insights/useAppTrackEvent.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

export const useHotKeyEventListener = () => {
  const { userExperience } = useStores();

  const trackEvent = useAppTrackEvent(AppInsightsEventNames.hotKeyUsed);
  const handleKeydown = useCallback(
    (ev: KeyboardEvent) => {
      userExperience.ui.userHotkeysHelper.handleKeydown(ev, trackEvent);
    },
    [trackEvent, userExperience.ui.userHotkeysHelper]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [trackEvent, handleKeydown]);
};
